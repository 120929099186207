body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --popup-bg-color: rgba(255, 255, 255, 0.95);
}

.mapboxgl-popup {
  z-index: 10;
}

.mapboxgl-popup-content {
  background-color: var(--popup-bg-color);
  padding: 8px 24px 8px 12px;
  border-radius: 6px;
  min-width: 175px;
}

.mapboxgl-popup-close-button {
  top: 6px;
  right: 10px;
}

.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: var(--popup-bg-color);
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  border-bottom-color: var(--popup-bg-color);
}
